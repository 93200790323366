import React from "react"
import { graphql } from "gatsby"
import { Container } from '@material-ui/core';
import Header from "../layout/Header"
import Articles from "../layout/home/Articles"
import Seo from "../components/Seo"
// import bck from "../images/bck.png"
import intro from "../layout/home/intro.svg"
import "../layout/home/index.css"

const styles = {
  intro: {
    // position: 'absolute',
    backgroundImage: `url(${intro})`, //content-box 
    backgroundSize: '100% 100%',
    height: '30rem',
    width: '100%',
    top: 0,
  },
  content: {
    position: 'relative',
    marginTop: '-12rem',
    background: '#fff',
    zIndex: 100,
    marginBottom: '2rem',
  },
  footerRoot: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  footer: {
    background: '#fff',
  }
}

const IndexPage = ({
  data,
  lang='en',
  location,
}) => {
  return (
    <div css={styles.root}>
      <Seo
        lang={"en"}
        title="Adaltas Cloud Academy"
        description={``}
        keywords={[].join(', ')}
        slug={location.pathname}
      />
      <Header />
      <div css={styles.intro} />
      <Container maxWidth="md" css={styles.content}>
        <Articles articles={data.articles} />
      </Container>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query {
    articles: allCloudArticle(
      filter: { lang: { eq: "en" } }
      sort: { order: DESC, fields: [date] }
    ) {
      nodes {
        date(formatString: "ll", locale: "en")
        frontmatter {
          title
          date
        }
        lang
        parent {
          ... on MarkdownRemark {
            html
            excerpt(pruneLength: 500)
          }
        }
        slug
      }
    }
  }
`

import React from "react"
import {
  Avatar,
  List, ListItem, ListItemAvatar, ListItemText,
  Typography,
} from "@material-ui/core"

const IndexPage = ({
  articles,
  lang='en',
}) => {
  return (
    <List>
      {articles.nodes.map(node => (
        <ListItem key={node.slug} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
          </ListItemAvatar>
          <ListItemText
            primary={node.frontmatter.title}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {node.date}
                  {" — "}
                </Typography>
                {node.parent.excerpt}
              </React.Fragment>
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

export default IndexPage
